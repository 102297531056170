@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300&display=swap');

a,
a:link,
a:visited,
a:hover,
a:active{
    text-decoration: none;
    color: var(--text);
}

:root {
  font-size: 18px;
  font-family: sans-serif;
  --transition: 200ms;
}

.light-theme {
  --bg-primary: #D6D5CB;
  --bg-nav-prim: #E4E3DB;
  --bg-nav-sec: #FFF7EF;
  --highlight: #9A8800;
  --text: #000000;
  --disabled-text: #b9b9b9;
}

.dark-theme {
  --bg-primary: #292a34;
  --bg-nav-prim: #1b1c24;
  --bg-nav-sec: #3d3d3d;
  --highlight: #6577ff;
  --text: #FFFFFF;
  --disabled-text: #797979;
}

body {
  margin: 0;
  padding: 0;
  background: var(--bg-primary);
  background-attachment: scroll;
  background-color: var(--bg-primary);
}

body::-webkit-scrollbar {
  width: 0.6rem;
}

body::-webkit-scrollbar-track {
  background: var(--bg-primary);
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb {
  background: var(--highlight);
  border-radius: 5px;
}

main {
  margin-left: 5rem;
  padding: 1rem;
  color: var(--text);
  height: 100vh;
  /* overflow: hidden; */
}

.page-container {
  position: relative;
  padding-bottom: 4rem;
}

/* NAVBAR */

.navbar {
  position: fixed;
  background-color: var(--bg-nav-prim);
  transition: 200ms ease;
  z-index: 2;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.nav-list > .nav-item:not(:first-child) {
  justify-content: center;
}

.nav-item {
  width:100%;
}

.nav-list #logo {
  background-color: var(--bg-nav-sec);
  font-size: 2rem;
  line-height: 85%;
}

.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  color: var(--text);
  text-decoration: none;
  transition: var(--transition);
}

.nav-link:hover:not(#logo-link,#theme-button){
  color: var(--highlight);
  border-right: 5px solid;
}

.nav-link:hover:not(#theme-button) svg {
  filter:opacity(0%)
}

.nav-link:hover:not(#theme-button) .link-text{
  filter: opacity(100%)
}

.active:not(#logo-link) {
  color: var(--highlight);
  border-right: 5px solid;
}

.link-text {
  font-family: 'Darker Grotesque', sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  transition: filter 300ms;
  filter:opacity(0%);
  position: absolute;
}

.nav-link svg {
  transition: filter 300ms;
  min-width: 2rem;
  margin: 0 1.5rem;
}

#theme-nav {
  margin-top:auto;
}

#theme-wrap {
  position: absolute;
  display: none;
  width: 300px;
  height: 250px;
}

.themes {
  position: absolute;
  margin-left: 210px;
  margin-top: -100px;
  background-color: var(--bg-nav-prim);
  list-style: none;
  height: 250px;
  width: 150px;
  display: none;
  border-radius: 10px;
}

#theme-nav:hover .themes{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#theme-nav:hover #theme-wrap{
  display: block;
}

.theme-item {
  text-align: center;
  height: 80px;
  display: flex;
}

.theme-item svg {
  width: 45px;
  height: auto;
}

.theme-item:hover {
  transition: 200ms ease;
  color: var(--highlight);
  cursor: pointer;
}

.theme-text{
  margin-top: 30px;
}

#carat-left{
  position: absolute;
  margin-left: -65px;
  margin-top: 175px;
  width: auto;
  height: 30px;
  color: var(--bg-nav-prim);
}

@media only screen and (max-width: 749px) {

  main {
    margin-left: 0;
  }

  .navbar {
    bottom: 0;
    width: 100%;
    height: 5rem;
    position: fixed;
  }

  .nav-list {
    flex-direction: row;
    width:100%;
  }

  #logo{ 
    display: none;
  }

  #theme-nav {
    display: none;
  }

  .nav-item:not(#logo) {
    width: 100%;
  }

  .nav-item svg {
    max-width: 50px;
  }

  .nav-link {
    justify-content: center;
  }

  .active:not(#logo-link) {
    box-sizing: border-box;
    border-right: 0;
    border-top: 5px solid;
  }

  .nav-link:hover:not(#logo-link) {
    box-sizing: border-box;
    border-right: 0;
    border-top: 5px solid;
  }

  #home-name {
    font-size: 3rem;
  }

  #home-desc {
    font-size: 1.6rem;
    font-family: 'Roboto', sans-serif;
  }

  .buttons {
    margin-top: 8vh;
    z-index: 1;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  
  .dir-button {
    border: 5px solid var(--text);
    color: var(--text);
    height: 76px;
    margin: 0 3.5vw;
    transition: 150ms ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  
  .dir-button:hover {
    background-color: var(--highlight);
    border: 5px solid var(--highlight);
  }
  
  .dir-button svg {
    position: absolute;
    max-width: 50px;
    padding-top: 10px;
    transition: 150ms ease;
    padding-bottom: 30px;
  }
  
  .button-text {
    font-family: 'Darker Grotesque', sans-serif;
    font-weight:normal;
    font-size: 1.2rem;
    padding: 0.2rem 0.5rem;
    padding-top: 50px;
    text-decoration: none;
  }

  .project-wrp {
    margin: 0 auto;
    width: 90vw;
  }

  .projects {
    margin-bottom: 5rem;
  }
}

@media only screen and (min-width: 750px) {
  .navbar{
    top: 0;
    height: 100%;
    max-width: 5rem;
    position: fixed;
  }

  #home-name {
    font-size: 6rem;
    line-height: 6rem;
  }

  #home-desc {
    margin-top:-2rem;
    font-size: 2rem;
  }
  
  .home .lead {
    padding-top: 2rem;
    padding-left: 3rem;
  }

  .buttons {
    z-index: 1;
    margin: 10vh 10vw;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  
  .dir-button {
    background: linear-gradient(to bottom, rgba(121, 114, 114, 0) 50%, var(--highlight) 50%);
    background-size: 100% 200%;
    background-position:left top;
    border: 5px solid var(--text);
    color: var(--text);
    min-width: 150px;
    height: 76px;
    margin: 0 5vw;
    transition: 150ms ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  
  .dir-button:hover {
    background-position:right bottom;
    border: 5px solid var(--highlight);
  }
  
  .dir-button:hover .button-text {
    filter: opacity(100%);
    transform: translateX(30px);
    text-decoration: none;
  }
  
  .dir-button:hover svg {
    transform: translateX(-40px);
  }
  
  .dir-button svg {
    position: absolute;
    max-width: 50px;
    padding-top: 10px;
    transition: 150ms ease;
    padding-bottom: 10px;
  }
  
  .button-text {
    filter: opacity(0);
    transition: 150ms ease;
    font-family: 'Darker Grotesque', sans-serif;
    font-weight: lighter;
    font-size: 1.5rem;
    padding-bottom: 8px;
    text-decoration: none;
  }

  .project-wrp {
    width: 50vw;
  }

}

footer {
  position: absolute;
  bottom: 0;
  background-color: var(--bg-nav-sec);
  width: 100%;
  height: 4rem;
  color: var(--text);
  font-size: 15px;
  align-content: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.footer-text {
  line-height: 0.5rem;
}

.home {
  align-content: center;
  justify-content: center;
  text-align: left;
}

#home-name {
  font-weight: bold;
}

#home-desc {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

.h1 {
  font-size: 3rem;
}

.project-wrp {
  font-family: 'Darker Grotesque', sans-serif;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2vw;
  /* border: 1px solid black; */
}

.left-clm {
  display: grid;
}

.right-clm {
  display: grid;
}

.project-title img {
  width: 100%;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
}

.project-title p {
  font-size: 1.1rem;
}

.project-title a {
  all: unset;
  cursor: pointer;
  text-decoration: underline;
  font-size: 2.3rem;
}

.features h2 {
  text-decoration: underline;
}

.made h2 {
  text-decoration: underline;
}

.proj-lst {
  list-style-type: circle;
}

.proj-item {
  font-size: 1.2rem;
  font-style: normal;
}

.proj-button {
  color:black;
  display: block;
  width: 50px;
  height: 50px;
}

.proj-button svg {
  color: var(--text);
}

.proj-button-link {
  text-decoration: none;
  font-size: 1.1rem;
  color: var(--text);
}

.links {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
  align-items: center;
}

.git-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10%;
}

.view-link {
  float: left;
  color: var(--text);
}

.git-link svg {
  width: 65px;
  margin-top: -14px;
  margin-left: -5px;
}

.resume {
  display: flex;
  flex-direction: row;
  height: 792px;
  position: absolute;
  margin-top: 30px;
}

.pdf-viewer {
  width: 612px !important;
  height: 792px!important;
  margin-left: 6vw;
  position: absolute;
  display: flex;
  justify-content: center;
}

.react-pdf__Page__canvas {
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  filter: drop-shadow(10px 20px 10px #0000003d);
}

.react-pdf__Page__textContent {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, 0%) !important;
}

.react-pdf__Page__annotations {
  height: 0px;
}

.react-pdf__Document {
  width: 612px !important;
  height: 792px;
}


.page-controls {
  border-color: black;
  opacity: 0;
  z-index: 1 !important;
  top: 92%;
  text-align: center;
  position: absolute;
  display: flex;
  height: 40px;
  font-size: 0.9em;
  background-color: var(--bg-nav-sec);
  border-radius: 10px;
  filter: drop-shadow(3px 5px 6px #0000003d);
  transition:opacity 200ms;
}

.page-controls button {
  background-color: var(--bg-nav-sec);
  border: none;
  font-size: 15px;
  text-align: center;
  width: 40px;
  color: var(--disabled-text);
}

.button-left {
  border-radius: 10px 0px 0px 10px;
}

.button-right {
  border-radius: 0px 10px 10px 0px;
}

.page-controls button:enabled:hover {
  background-color: var(--bg-nav-prim);
  cursor: pointer;
}

.page-controls button:enabled {
  color: var(--text);
}

.page-controls p {
  color: var(--text);
  margin-top: 10px;
  width: 60px;
  background-color: var(--bg-nav-sec);
}

.pdf-viewer:hover .page-controls {
  opacity: 100;
}

.download-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* margin-left: 50vw; */
  /* margin-top: 40px; */
  position: absolute;
  height: 70vh;
  min-height: 500px;
}

.download-button {
  background: linear-gradient(to bottom, rgba(121, 114, 114, 0) 50%, var(--highlight) 50%);
  background-size: 100% 200%;
  background-position:left top;  border: 5px solid var(--text);
  color: var(--text);
  height: 76px;
  width: 150px;
  transition: 150ms ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.download-button svg {
  position: absolute;
  padding-top: 30px;
  transition: 150ms ease;
  padding-bottom: 30px;
}

.download-button:hover {
  background-position:right bottom;
  border: 5px solid var(--highlight);
  cursor: pointer;
}

.download-button:hover .button-text {
  filter: opacity(100%);
  transform: translateX(30px);
  text-decoration: none !important;
}

.download-button:hover svg {
  transform: translateX(-40px);
}

.dl-button-wrap {
  display: flex;
  align-items: center;
}

.doc-type {
  max-width: 45px;
}

#download-svg {
  padding-left: 50px;
  padding-top: 80px;
  max-width: 25px;
}

#print-icon {
  max-width: 62px !important;
}

#save-text {
  font-size: 3em;
}

#save-desc {
  font-style: 1em;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  width: 500px;
}

.download-descs {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  margin-left: 50px;
}

#resume-buttons {
  margin-left: 50vw;
}

.disabled {
  color: #8a8a8a !important;
}

.disabled svg {
  color: #8a8a8a !important;
}

.img-wrap {
  position: relative;
  display: block;
}

.still:hover {
  opacity: 0;
}

.animated {
  position: absolute;
}

/* Mobile styles for resume page */
@media only screen and (max-width: 1436px) {
  #save-text {
    /* margin-top: 190px; */
  }

  #resume-buttons {
    width: 698px;
    margin-top: 920px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 900px;
  }

  .pdf-viewer {
    width: 612px;
    margin-left: 20vw;
  }
}

/* Contact page */

.contact {
  height: 100%;
}

.contact hr {
  width: 95%;
  margin: 30px 0px;
}

#contact-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  font-family: 'Roboto', sans-serif;
}

#contact-form-wrapper {
  width: 40%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: var(--bg-nav-prim);
  filter: drop-shadow(10px 20px 10px #0000003d);
}

#contact-form {
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#contact-form input {
  height: 40px;
}

#contact-form p {
  font-weight: bold;
  font-size: 1.3rem;
  margin: 0;
}

#contact-form textarea {
  resize: none;
  height: 40%;
}

#email-text {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#socials {
  height: 10%;
}

.socials svg {
  height: 60px;
}

.socials {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socials p {
  font-size: 1.2rem;
  font-weight: bold;
}